<template>
  <div id="login-screen">
    <div class="logo"></div>
    <h1>NTS SR</h1>
    <div class="inputs-wrapper">
      <form @submit="submitLogin">
        <input type="text" id="evc" class="evc" maxlength="10" minlength="10" required="required" autocomplete="none" placeholder="EVČ *" v-model="evc" />
        <input type="password" class="pin" maxlength="4" minlength="4" required="required" autocomplete="none" placeholder="Pin" v-model="pin" />
        <span class="form-error" v-if="loginFormError !== false">{{loginFormError}}</span>
        <input type="submit" class="submit btn btn-primary" value="Prihlásiť sa" @click="submitLogin"/>
      </form>
      <router-link to="/activation" class="submit btn btn-primary activation-button"><span>Aktivovať el. kartu darcu</span></router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginScreen',
  data () {
    return {
      evc: localStorage.getItem('evc') || '',
      pin: '', // TODO: 4 Input PIN
      loginFormError: false
    }
  },
  methods: {
    ...mapActions([
      'login'
    ]),
    submitLogin (e) {
      e.preventDefault()
      const evcValidation = this.evc !== '' && this.evc.length === 10 && /^[0-9]+$/.test(this.evc)
      const pinValidation = this.pin !== '' && this.pin.length === 4 && /^[0-9]+$/.test(this.pin)
      if (evcValidation && pinValidation) {
        localStorage.setItem('evc', this.evc)
        this.loginFormError = false
        this.login({ evc: this.evc, pin: this.pin })
      } else {
        this.loginFormError = 'Pre prihlásenie je potrebné správne vyplniť EVČ a PIN'
      }
    }
  }
}
</script>

<style scoped lang="scss">
#login-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: var(--background-color);
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    display: block;
    width: 100px;
    height: 100px;
    background-image: url("../assets/notify.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 15px;
    margin-top: -70px;
  }

  h1 {
    margin-bottom: 50px;
    font-weight: bold;
    color: var(--text-color);
  }

  .inputs-wrapper {
    text-align: center;
  }
  .activation-button {
    display: inline-block;
    position: absolute;
    bottom: 75px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 60%;
    max-width: 400px;
    @media (max-width: 375px) {
      width: 70%;
      max-width: initial;
    }
  }
}
</style>
