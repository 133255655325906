// let self
import axios from 'axios'
import sha256 from 'crypto-js/sha256'

class _API {
  constructor () {
    // self = this
    this.storageKey = 'authToken'
    this.api = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}`
    })
  }

  getAuthToken () {
    const storedToken = localStorage.getItem(this.storageKey)
    return typeof storedToken === 'string' && storedToken.length > 0 ? storedToken : null
  }

  removeAuthToken () {
    localStorage.removeItem(this.storageKey)
  }

  setAuthToken (token) {
    localStorage.setItem(this.storageKey, token)
  }

  request (reqOptions, reqHeaders) {
    const token = this.getAuthToken()
    if (token !== null || reqOptions.skipToken === true) {
      const req = {
        ...reqOptions,
        headers: {
          ...reqHeaders,
          ...(reqOptions.skipToken === true ? {} : { 'X-EKDToken': token })
        }
      }
      return this.api.request(req)
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      return new Promise((resolve, reject) => reject('400'))
    }
  }

  async login ({ evc, pin }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const bodyFormData = new FormData()
      bodyFormData.append('evc', evc)
      bodyFormData.append('pin', sha256(pin).toString())

      const res = await this.api.request({
        url: 'login.php',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: bodyFormData
      })
      this.setAuthToken(res.data)
      return true
    } catch (err) {
      throw err.response?.data?.Message ?? err
    }
  }

  async requestSmsCode ({ evc }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const bodyFormData = new FormData()
      bodyFormData.append('evc', evc)

      await this.api.request({
        url: 'requestSmsCode.php',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: bodyFormData
      })
      return true
    } catch (err) {
      throw err.response?.data?.Message ?? err
    }
  }

  async activateApp ({ activatePIN, evc, pin }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const bodyFormData = new FormData()
      bodyFormData.append('activatePIN', activatePIN)
      bodyFormData.append('deviceId', (Math.random() + 1).toString(36).substring(2))
      bodyFormData.append('evc', evc)
      bodyFormData.append('pin', sha256(pin).toString())

      await this.api.request({
        url: 'activation.php',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: bodyFormData
      })
      return true
    } catch (err) {
      throw err.response?.data?.Message ?? err
    }
  }

  async fetchUserHome () {
    return this.request({
      url: 'basic.php?service=' + encodeURIComponent('/darca/Vitajte'),
      method: 'GET'
    })
  }

  async fetchUserData () {
    return this.request({
      url: 'basic.php?service=' + encodeURIComponent('/darca/PreukazDarcu'),
      method: 'GET'
    })
  }

  async fetchUserHistory () {
    return this.request({
      url: 'basic.php?service=' + encodeURIComponent('/darca/odber'),
      method: 'GET'
    })
  }

  async fetchOdberDetail (odberId) {
    return this.request({
      url: 'basic.php?service=' + encodeURIComponent(`/darca/odber/${odberId}`),
      method: 'GET'
    })
  }

  async fetchOdberIvko (odberId) {
    return this.request({
      url: 'basic.php?service=' + encodeURIComponent(`/darca/odber/${odberId}/lvko`),
      method: 'GET'
    })
  }

  async fetchOdberIvkoDetail ({ odberId, ivkoId }) {
    return this.request({
      url: 'basic.php?service=' + encodeURIComponent(`/darca/lvko/${ivkoId}/${odberId}`),
      method: 'GET'
    })
  }

  async fetchIvkoEnums () {
    return this.request({
      url: 'basic.php?service=' + encodeURIComponent('/ciselniky/ParametreKO'),
      method: 'GET'
    })
  }

  async fetchDonationPlaces () {
    return this.request({
      url: 'basic.php?service=' + encodeURIComponent('/ciselniky/Pracoviska'),
      method: 'GET',
      skipToken: true
    })
  }
}

const API = new _API()
export default API
