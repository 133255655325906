<template>
  <Header title="Prehľad odberov" />
  <div id="home" class="container">
    <div class="row remaining-days">
      <span class="mb-3 remaining-days-desc">Ďalší odber celej krvi je možný za</span>
      <div class="col-12">
        <h3 class="remaining-days-text">{{getRemainingDays(true)}}</h3>
        <ProgressBar :percentage="calcPercentage(getRemainingDays(false))" />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <span class="history-label item-list-heading">História odberov (max 10):</span>
        <ul class="history-list item-list" v-if="userHistory?.length > 0">
          <li v-for="(item, index) in userHistory" v-bind:key="index" class="history-item item-list-item" @click="redirectOdberDetail(item.Id)">
            <span class="line-text">
              <span class="line-primary">{{ new Date(item.DatumOdberu).toLocaleDateString() }} - {{ formatAmount(item.MnozstvoMl) }}</span>
              <span class="line-secondary">{{ item.TypOdberu?.replace(/\w/, c => c.toUpperCase()) }} - {{ item.Pracovisko }}</span>
            </span>
            <a class="info-button"><i class="bi bi-info-circle-fill"/></a>
          </li>
        </ul>
        <span v-if="userHistory === null || userHistory?.length === 0" class="no-data">Nemáte žiadny odber</span>
      </div>
    </div>
    <pre class="d-none">{{userHome}}</pre>
    <pre class="d-none">{{userHistory}}</pre>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Header from '@/components/Header'
import ProgressBar from '@/components/ProgressBar'

export default {
  name: 'Home',
  components: {
    Header,
    ProgressBar
  },
  beforeMount () {
    this.fetchData()
  },
  watch: {
    '$store.state.isLoggedIn': function () {
      this.fetchData()
    }
  },
  computed: mapState([
    'isLoggedIn',
    'userHome',
    'userHistory',
    'odberDetail'
  ]),
  methods: {
    fetchData () {
      if (this.isLoggedIn) {
        this.userHome === null && this.$store.dispatch('fetchUserHome')
        this.userHistory === null && this.$store.dispatch('fetchUserHistory')
      }
    },
    getRemainingDays (includeDay) {
      const difference = new Date(this.userHome?.TerminNasledOdberu).getTime() - new Date().getTime()
      let days = Math.ceil(difference / (1000 * 3600 * 24))
      days = !isNaN(days) ? days : 0
      return days + (includeDay ? ' ' + ((days >= 5 || days === 0) ? 'dní' : (days > 1 ? 'dni' : 'deň')) : '')
    },
    formatAmount (amount) {
      return (isNaN(amount) ? '0' : Number(amount)) + ' ml'
    },
    calcPercentage (days) {
      const fullDays = this.userHome?.Pohlavie?.toUpperCase() === 'M' ? 90 : 120
      return (1 - (Number(days) / fullDays)) * 100
    },
    redirectOdberDetail (odberId) {
      this.$router.push('/odberDetail/' + odberId)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../functions.scss";

  #home {
    position: relative;
    .remaining-days {
      margin-top: 30px;
      margin-bottom: 15px;
      padding-bottom: 30px;
      border-bottom: 2px solid var(--border-color);

      .remaining-days-desc {
        font-size: rem(18);
      }
      .remaining-days-text {
        font-weight: bold;
        display: block;
        font-size: rem(40);
      }
    }
    .no-data {
      display: block;
      margin: 15px 0;
      font-size: rem(18);
    }
  }
</style>
