import { createStore } from 'vuex'
import router from '../router'
import API from '../api'

const tryRequest = async (commit, mutation, requestFn, data) => {
  try {
    if (mutation && commit) {
      commit(mutation, await API[requestFn](data))
    } else {
      await API[requestFn](data)
    }
  } catch (err) {
    console.log('request error', requestFn, err)
    const isNetworkError = err.message?.indexOf('Network Error') >= 0
    const isAuthError = err?.response?.status === 403 || err?.response?.status === 401

    if (isAuthError || isNetworkError) {
      API.removeAuthToken()
      commit('setLoggedIn', false)
      isNetworkError && commit('reportError', err)
    } else {
      commit('reportError', err)
    }
    commit(mutation, { data: null })
  }
}
export default createStore({
  state: {
    userHome: null,
    userData: null,
    userHistory: null,
    userSex: null,
    odberDetail: null,
    odberIvko: null,
    odberIvkoDetail: null,
    ivkoEnums: null,
    donationPlaces: null,
    loaders: [],
    errors: [],
    isLoggedIn: false,
    smsCodeRequested: false
  },
  getters: {
    isLoading (state) {
      return state.loaders.length > 0
    }
  },
  mutations: {
    setUserHome (state, data) {
      state.userHome = data.data
      state.userSex = data.data?.Pohlavie
    },
    setUserData (state, data) {
      state.userData = data.data
    },
    setUserHistory (state, data) {
      state.userHistory = data.data
    },
    setOdberDetail (state, data) {
      state.odberDetail = data.data
    },
    setOdberIvko (state, data) {
      state.odberIvko = data.data
    },
    setOdberIvkoDetail (state, data) {
      state.odberIvkoDetail = data.data
    },
    setIvkoEnums (state, data) {
      state.ivkoEnums = data.data
    },
    setDonationPlaces (state, data) {
      state.donationPlaces = data.data
    },
    addLoader (state, data) {
      state.loaders.push(data)
    },
    reduceLoader (state) {
      state.loaders.pop()
    },
    reportError (state, data) {
      state.errors.unshift(data)
      setTimeout(() => {
        state.errors.pop()
      }, 3000)
    },
    removeError (state) {
      state.errors.pop()
    },
    setLoggedIn (state, data) {
      state.isLoggedIn = data
    },
    setSmsCodeRequested (state, data) {
      state.smsCodeRequested = data
    }
  },
  actions: {
    checkLogin ({ commit }) {
      commit('setLoggedIn', !!API.getAuthToken())
    },
    async login ({ commit }, { evc, pin }) {
      commit('addLoader', 'login')
      try {
        await API.login({ evc, pin })
        commit('setLoggedIn', true)
      } catch (err) {
        commit('setLoggedIn', false)
        commit('reportError', err)
      }
      commit('reduceLoader')
    },
    logout ({ commit }) {
      API.removeAuthToken()
      commit('setLoggedIn', !!API.getAuthToken())
      commit('setUserHistory', { data: null })
      commit('setUserHome', { data: null })
      commit('setUserData', { data: null })
    },
    async requestSmsCode ({ commit }, { evc }) {
      commit('addLoader', 'requestSmsCode')
      try {
        await API.requestSmsCode({ evc })
        commit('setSmsCodeRequested', true)
      } catch (err) {
        commit('reportError', err)
        commit('setSmsCodeRequested', false)
      }
      commit('reduceLoader')
    },
    async setSmsCodeState ({ commit }, { smsState }) {
      commit('setSmsCodeRequested', smsState)
    },
    async activateApp ({ commit }, { activatePIN, evc, pin }) {
      commit('addLoader', 'activateApp')
      try {
        await API.activateApp({ activatePIN, evc, pin })
        commit('setSmsCodeRequested', false)
        router.push('/')
        // TODO: Success activation message
      } catch (err) {
        commit('reportError', err)
      }
      commit('reduceLoader')
    },
    async fetchUserHome ({ commit }) {
      commit('addLoader', 'fetchUserHome')
      await tryRequest(commit, 'setUserHome', 'fetchUserHome')
      commit('reduceLoader')
    },
    async fetchUserData ({ commit }) {
      commit('addLoader', 'fetchUserData')
      await tryRequest(commit, 'setUserData', 'fetchUserData')
      commit('reduceLoader')
    },
    async fetchUserHistory ({ commit }) {
      commit('addLoader', 'fetchUserData')
      await tryRequest(commit, 'setUserHistory', 'fetchUserHistory')
      commit('reduceLoader')
    },
    async fetchOdberDetail ({ commit }, odberId) {
      commit('addLoader', 'fetchOdberDetail')
      commit('setOdberDetail', { data: null })
      await tryRequest(commit, 'setOdberDetail', 'fetchOdberDetail', odberId)
      commit('reduceLoader')
    },
    async fetchOdberIvko ({ commit }, odberId) {
      commit('addLoader', 'fetchOdberIvko')
      commit('setOdberIvko', { data: null })
      await tryRequest(commit, 'setOdberIvko', 'fetchOdberIvko', odberId)
      commit('reduceLoader')
    },
    async fetchOdberIvkoDetail ({ commit }, { odberId, ivkoId }) {
      commit('addLoader', 'fetchOdberIvkoDetail')
      commit('setOdberIvkoDetail', { data: null })
      await tryRequest(commit, 'setOdberIvkoDetail', 'fetchOdberIvkoDetail', { odberId, ivkoId })
      commit('reduceLoader')
    },
    async fetchIvkoEnums ({ commit }) {
      commit('addLoader', 'fetchIvkoEnums')
      await tryRequest(commit, 'setIvkoEnums', 'fetchIvkoEnums')
      commit('reduceLoader')
    },
    async fetchDonationPlaces ({ commit }) {
      commit('addLoader', 'fetchDonationPlaces')
      await tryRequest(commit, 'setDonationPlaces', 'fetchDonationPlaces')
      commit('reduceLoader')
    }
  },
  modules: {
  }
})
