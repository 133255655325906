import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import DonationCard from '@/views/DonationCard'

const routes = [
  {
    path: '/about-donation',
    name: 'aboutDonation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aboutDonation" */ '../views/AboutDonation.vue')
  },
  {
    path: '/donation-card',
    name: 'donationCard',
    component: () => import(/* webpackChunkName: "donationCard" */ '../views/DonationCard.vue')
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/odberDetail/:id',
    name: 'odberDetail',
    component: () => import(/* webpackChunkName: "odberDetail" */ '../views/OdberDetail.vue')
  },
  {
    path: '/awards',
    name: 'awards',
    component: () => import(/* webpackChunkName: "awards" */ '../views/Awards.vue')
  },
  {
    path: '/donation-places',
    name: 'donationPlaces',
    component: () => import(/* webpackChunkName: "donationPlaces" */ '../views/DonationPlaces.vue')
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import(/* webpackChunkName: "faqs" */ '../views/Faqs.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/activation',
    name: 'activation',
    component: () => import(/* webpackChunkName: "activation" */ '../views/Activation.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'home',
    component: Home
  }
  // TODO: Create Donation Places page
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
