<template>
  <Menu v-if="currentRouteName !== 'activation' && currentRouteName !== 'odberDetail'" :login-modal="loginRequired === true && isLoggedIn === false"/>
  <main id="page-wrap">
    <router-view v-if="loginRequired === false || isLoggedIn === true"/>
    <div id="nav-overlay" class="hidden"></div>
  </main>
  <LoadingScreen v-if="isLoading === true" />
  <LoginScreen v-if="loginRequired === true && isLoggedIn === false" />
  <ErrorHandler />
</template>

<script>
import LoadingScreen from '@/components/LoadingScreen.vue'
import LoginScreen from '@/components/LoginScreen.vue'
import ErrorHandler from '@/components/ErrorHandler.vue'
import Menu from '@/components/Menu.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    Menu,
    LoadingScreen,
    LoginScreen,
    ErrorHandler
  },
  computed: {
    ...mapGetters([
      'isLoading'
    ]),
    ...mapState([
      'isLoggedIn'
    ]),
    currentRouteName () {
      return this.$route.name
    },
    loginRequired () {
      return ['home', 'awards', 'donationCard', 'odberDetail'].indexOf(this.currentRouteName) > -1
    }
  },
  methods: {
    ...mapActions([
      'checkLogin'
    ])
  },
  beforeMount () {
    this.checkLogin()
  }
}
</script>

<style lang="scss">
@import "./functions.scss";
@import './variables.scss';
html {
  width: 100%;
  height: 100%;
  font-size: var(--font-size-base);
  font-family: var(--font-family);

}
body {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  color: var(--text-color);

}
#app {
  width: 100%;
  height: 100%;
  font-size: rem(17);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
}
#page-wrap {
  padding-top: 85px;
}
#nav-overlay {
  position: absolute;
  top: -30px;
  left: 0;
  right: -30px;
  bottom: -30px;
  z-index: 20;
  background: var(--background-color-transparent);
  // box-shadow: inset 0 0 15px 15px #000000DB;
  // transition: opacity 3s;
  // opacity: 1;
  &.hidden {
    display: none;
    // opacity: 0;
  }
}
</style>
