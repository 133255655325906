<template>
  <div class="progress-bar">
    <div class="progress-bar-filling" :style="{'right': 100-percentage + '%'}" />
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: [
    'percentage'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.progress-bar {
  width: 100%;
  height: 50px;
  background-color: var(--bs-gray);
  border: 1px solid var(--border-color);
  position: relative;

  .progress-bar-filling {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 100%;
    background-color: var(--primary-color);
    transition: all 1s ease-in-out;
  }
}
</style>
