<template>
  <div id="header">
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: [
    'title'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../functions.scss";

  #header {
    width: 100%;
    min-height: 70px;
    line-height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 15px;
    // padding-left: calc(var(--menu-burger-position) + var(--menu-burger-size-w) + 5px);
    background-color: var(--primary-color);
    border-bottom: 1px solid var(--border-color);
    z-index: 9;
    color: var(--header-text-color);

    h1 {
      line-height: inherit;
      margin: 0;
      padding: 0;
      font-weight: bold;
      font-size: rem(23);
    }
  }
</style>
