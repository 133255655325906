<template>
  <div id="nav">
    <div ref="sideNav" class="nav-container">
      <span class="nav-cross-button cross-style" @click="closeMenu">
        <span v-for="(x, index) in 2" :key="x" class="nav-cross" :style="{ position: 'absolute', width: '3px', height: '14px',transform: index === 1 ? 'rotate(45deg)' : 'rotate(-45deg)'}"></span>
      </span>

      <div class="nav-item-list">
        <router-link v-if="isLoggedIn === true" to="/" @click="closeMenu">
          <i class="bi" :class="'bi-clipboard-heart' + (currentTheme === 'light-mode' ? '-fill' : '')"></i>
          <span>Prehľad odberov</span>
        </router-link>
        <router-link v-if="isLoggedIn === true" to="/donation-card" @click="closeMenu">
          <i class="bi" :class="'bi-credit-card-2-front' + (currentTheme === 'light-mode' ? '-fill' : '')"></i>
          <span>Preukaz darcu</span>
        </router-link>
        <router-link v-if="isLoggedIn === true" to="/awards" @click="closeMenu">
          <i class="bi" :class="'bi-award' + (currentTheme === 'light-mode' ? '-fill' : '')"></i>
          <span>Ocenenia</span>
        </router-link>
        <router-link v-if="isLoggedIn === false" to="/" @click="closeMenu">
          <i class="bi bi-person-circle"></i>
          <span>Prihlásiť / Aktivovať</span>
        </router-link>
        <router-link to="/about-donation" @click="closeMenu">
          <i class="bi" :class="'bi-droplet' + (currentTheme === 'light-mode' ? '-fill' : '')"></i>
          <span>O darovaní krvi</span>
        </router-link>
        <!--router-link to="/donation-places" @click="closeMenu"><i class="bi bi-house"></i><span>Pracoviská</span></router-link-->

        <a href="https://www.ntssr.sk/aktualne-usmernenia-k-darovaniu-krvi" target="_blank" @click="closeMenu">
          <i class="bi" :class="'bi-exclamation-triangle' + (currentTheme === 'light-mode' ? '-fill' : '')"></i>
          <span>Aktuálne obmedzenia</span>
        </a>
        <a href="https://www.ntssr.sk/mobilna-aplikacia-elektronicka-karta-darcu" target="_blank" @click="closeMenu">
          <i class="bi" :class="'bi-phone' + (currentTheme === 'light-mode' ? '-fill' : '')"></i>
          <span>Podmienky používania</span>
        </a>
        <a href="https://ekdapi.ntssr.sk/PrivacyPolicy.html" target="_blank" @click="closeMenu">
          <i class="bi" :class="'bi-shield' + (currentTheme === 'light-mode' ? '-fill' : '') + '-check'"></i>
          <span>Ochrana osobných údajov</span>
        </a>
        <router-link to="/faqs" @click="closeMenu">
          <i class="bi bi-list-ol"></i>
          <span>Často kladené otázky</span>
        </router-link>
        <a href="#" @click="clickLogout" v-if="isLoggedIn === true">
          <i class="bi" :class="'bi-door-open' + (currentTheme === 'light-mode' ? '-fill' : '')"></i>
          <span>Odhlásiť</span>
        </a>
      </div>
      <a href="#" id="switchTheme" class="switch-theme-button" @click="changeTheme">
        <i class="bi bi-moon-fill" v-if="currentTheme === 'light-mode'"></i>
        <i class="bi bi-brightness-high-fill" v-else></i>
      </a>
    </div>
    <div ref="navBurgerButton" class="nav-burger-button" :class="loginModal === true ? 'loginModal' : ''" @click="openMenu">
      <span class="nav-burger-bars line-style" :style="{top:20 * (index * 2) + '%'}" v-for="(x, index) in 3" :key="index"></span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Menu',
  data () {
    return {
      isSideBarOpen: false,
      bodyOldStyle: '',
      currentTheme: document.querySelector('body').className
    }
  },
  props: [
    'loginModal'
  ],
  computed: {
    ...mapState([
      'isLoggedIn'
    ])
  },
  methods: {
    ...mapActions([
      'logout'
    ]),
    openMenu () {
      const width = this.width ? this.width + 'px' : '300px'
      this.$emit('openMenu')
      this.isSideBarOpen = true
      document.querySelector('#nav-overlay').classList.remove('hidden')
      this.$nextTick(function () {
        // this.$refs.sideNav.style.width = width
        this.$refs.sideNav.style.transform = 'translate3d(0px, 0px, 0px )'
      })

      this.bodyOldStyle = document.body.getAttribute('style') || ''
      document.body.style.overflowX = 'hidden'
      document.querySelector('#page-wrap').style.transform = `translate3d(${width}, 0px, 0px )`
      document.querySelector('#page-wrap').style.transition = 'all 0.5s ease 0s'
    },
    closeMenu () {
      const width = this.width ? this.width + 'px' : '300px'
      this.$emit('closeMenu')
      this.isSideBarOpen = false
      document.querySelector('#nav-overlay').classList.add('hidden')
      document.querySelector('#page-wrap').style.transition = 'all 0.5s ease 0s'
      document.querySelector('#page-wrap').style.transform = ''
      document.body.setAttribute('style', this.bodyOldStyle)
      // this.$refs.sideNav.style.width = '0px'
      this.$refs.sideNav.style.transform = `translate3d(-${width}, 0px, 0px )`
    },
    closeMenuOnEsc (e) {
      e = e || window.event
      if (e.key === 'Escape' || e.keyCode === 27) {
        this.closeMenu()
      }
    },
    documentClick (e) {
      const element = this.$refs.navBurgerButton
      let target = null
      if (e && e.target) {
        target = e.target
      }
      if (element && element !== target && !element.contains(target) && !this.hasClass(target, 'nav-container') && !this.hasClass(target, 'bi') && this.isSideBarOpen) {
        this.closeMenu()
      }
    },
    hasClass (element, className) {
      do {
        if (element.classList && element.classList.contains(className)) {
          return true
        }
        element = element.parentNode
      } while (element)
      return false
    },
    clickLogout (e) {
      e.preventDefault()
      this.closeMenu()
      this.logout()
      this.$router.push('/')
    },
    changeTheme (e) {
      e.preventDefault()
      if (this.currentTheme === 'light-mode') {
        document.querySelector('body').className = 'dark-mode'
        document.querySelector('meta[name="theme-color"]').setAttribute('content', '#202123')
        this.currentTheme = 'dark-mode'
      } else {
        document.querySelector('body').className = 'light-mode'
        document.querySelector('meta[name="theme-color"]').setAttribute('content', '#ffffff')
        this.currentTheme = 'light-mode'
      }
      localStorage.setItem('theme', this.currentTheme)
    }
  },
  mounted () {
    if (!this.disableEsc) {
      document.addEventListener('keyup', this.closeMenuOnEsc)
    }
  },
  created: function () {
    document.addEventListener('click', this.documentClick)
  },
  unmounted: function () {
    document.removeEventListener('keyup', this.closeMenuOnEsc)
    document.removeEventListener('click', this.documentClick)
  },
  watch: {
    isOpen: {
      deep: true,
      immediate: true,
      handler (newValue, oldValue) {
        this.$nextTick(() => {
          if (!oldValue && newValue) {
            this.openMenu()
          }
          if (oldValue && !newValue) {
            this.closeMenu()
          }
        })
      }
    }

  }
}
</script>

<style scoped lang="scss">
@import "../functions.scss";

#nav {
  .nav-container {
    height: 100%; /* 100% Full-height */
    width: 300px; /* 0 width - change this with JavaScript */
    transform: translate3d(-300px, 0px, 0px );
    position: fixed; /* Stay in place */
    z-index: 1000; /* Stay on top */
    top: 0;
    left: 0;
    background-color: var(--menu-nav-bg-color); /* Black*/
    border-right: var(--menu-nav-border-right);
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/

    .cross-style {
      position: absolute;
      top: 12px;
      right: 6px;
      cursor: pointer;
    }
    .nav-cross {
      background: var(--menu-cross-color);
    }
    .nav-cross-button {
      height: 24px;
      width: 24px;
    }

    .nav-item-list {
      color: var(--text-color);
      margin-left: 15px;
      text-align: left;
      font-size: var(--menu-list-font-size);
      > .router-link-active {
        span {
          color: var(--menu-list-icon-color)
        }
        i {
          color: var(--menu-list-icon-color)
        }
      }
    }

    .nav-item-list > .router-link-active {
      color: var(--menu-list-icon-color)
    }
    .nav-item-list > * {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0.7em;
      line-height: 28px;
    }
    .nav-item-list > * > span {
      margin-left: 10px;
      font-weight: 700;
      color: var(--menu-list-color);
      transition: all 0.3s linear;
    }
    .nav-item-list > * > i {
      font-size: var(--menu-list-icon-size);
      color: var(--menu-list-icon-color);
      transition: all 0.3s linear;
    }
  }
  .nav-burger-button {
    position: fixed;
    width: var(--menu-burger-size-w);
    height: var(--menu-burger-size-h);
    left: var(--menu-burger-position);
    top: var(--menu-burger-position);
    cursor: pointer;
    z-index: 16;

    .nav-burger-bars {
      background-color: var(--menu-burger-color);
    }

    &.loginModal {
      .nav-burger-bars {
        background-color: var(--menu-burger-color-login);
      }
    }

    .line-style {
      position: absolute;
      height: 20%;
      left: 0;
      right: 0;
    }
  }

  #switchTheme {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    color: var(--menu-list-color);
    font-size: rem(22);
    text-decoration: none;
  }
}
</style>
