<template>
  <div class="error-handler-component">
    <div class="error-item" v-for="error in uniqueErrors" v-bind:key="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ErrorHandler',
  computed: {
    ...mapState([
      'errors'
    ]),
    uniqueErrors () {
      const unique = []
      const uniqueStrings = []
      for (let i = 0; i < this.errors.length; i++) {
        const error = this.errors[i]
        const errorString = error?.response?.status + ',' + error?.message
        if (uniqueStrings.indexOf(errorString) <= -1) {
          uniqueStrings.push(errorString)
          unique.push(error)
        }
      }
      return unique
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.error-handler-component {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 495;
  .error-item {
    margin: 15px;
    padding: 15px;
    background: #cb3847;
    border-radius: 5px;
    text-align: left;
    transition: all 0.3s linear;
    color: #e8eaed;
  }
}
</style>
