<template>
  <div id="loading-screen">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen'
}
</script>

<style scoped lang="scss">
#loading-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color-transparent);
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner-border {
    color: var(--primary-color);
    width: 100px;
    height: 100px;
    border-width: 10px;
  }
}
</style>
